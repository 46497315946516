import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:5001/blackshipp-b87d8/us-central1'
  baseURL: 'https://us-central1-blackshipp-b87d8.cloudfunctions.net'
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';
    }

    return config;
  },
  error => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
