import { ReactNode } from 'react';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// hooks
import useLocales from 'hooks/useLocales';

const localeMap: { [key: string]: Locale } = {
  en: enLocale,
  es: esLocale,
};

interface LocaleProviderProps {
  children: ReactNode
}

export default function LocaleProvider({
  children
}: LocaleProviderProps) {
  const { currentLang } = useLocales();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[currentLang.value]}>
      {children}
    </LocalizationProvider>
  )
}
