import { map, filter } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { PackageData } from '../../@types/package';

// ----------------------------------------------------------------------

type PackageState = {
  isLoading: boolean;
  error: boolean;
  packageList: PackageData[];
};

const initialState: PackageState = {
  isLoading: true,
  error: false,
  packageList: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.packageList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getPackageList (){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/package/manage-packages');
      dispatch(slice.actions.getUserListSuccess(response.data.packages));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

}