// material
import { Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
// components
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LogoutButton from './LogoutButton';

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  return (
    <AppBar>
      <Toolbar>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: '#fff' }}>
          <MenuIcon />
        </IconButton>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}
          {/* <AccountPopover /> */}
          <LogoutButton />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
