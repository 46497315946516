import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';

export default function LogoutButton() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await logout?.();
      if (isMountedRef.current) {
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <Tooltip title="Logout">
      <IconButton onClick={handleLogout} sx={{ color: '#fff' }}>
        <ExitToAppIcon />
      </IconButton>
    </Tooltip>
  )
}
