// https://material-ui.com/customization/default-theme/?expand-path=$.zIndex

const zIndex = {
  mobileStepper: 600, // 1000
  speedDial: 650, // 1050
  appBar: 700, // 1100
  drawer: 800, // 1200
  modal: 900, // 1300
  snackbar: 1000, // 1400
  tooltip: 1100, // 1500
}

export default zIndex;
