import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// slices
import userReducer from './slices/user';
import orderReducer from './slices/order';
import packageReducer from './slices/package';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['package']
};

const rootReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  package: packageReducer,
});

export { rootPersistConfig, rootReducer };
