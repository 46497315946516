import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { OrderManager } from '../../@types/order';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  orderList: OrderManager[];
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  orderList: []
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MANAGE USERS
    getOrderListSuccess(state, action) {
      state.isLoading = false;
      state.orderList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOrderList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/order/manage-orders');
      dispatch(slice.actions.getOrderListSuccess(response.data.orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
