// routes
import { PATH_DASHBOARD } from 'routes/paths';
// icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// ----------------------------------------------------------------------

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'sideBarNav.monitoring',
        path: PATH_DASHBOARD.overview,
        icon: <VisibilityIcon />
      },
    ]
  },
  {
    items: [
      {
        title: 'sideBarNav.settings',
        path: PATH_DASHBOARD.settings.root,
        icon: <SettingsIcon />,
        children: [
          {
            title: 'Company profile',
            path: PATH_DASHBOARD.settings.companyProfile,
            icon: <StoreIcon />
          },
          {
            title: 'Default values',
            path: PATH_DASHBOARD.settings.defaultValues,
            icon: <AddAlertIcon />
          },
          {
            title: 'Shipping labels',
            path: PATH_DASHBOARD.settings.shippinLabels,
            icon: <NotificationsActiveIcon />
          },
        ]
      },
      {
        title: 'sideBarNav.help',
        path: 'https://www.blackshipp.com/',
        icon: <HelpIcon />
      },
      // { title: 'sideBarNav.logout', path: '/logout', icon: <ExitToAppIcon /> }
    ]
  }
];

export default sidebarConfig;
